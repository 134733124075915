import React, { useEffect, useRef, useState } from "react";
import { Header } from "./Elements.tsx";
import Config from "../Config.ts";
import Auth from "../Auth.ts";
import { publicAuthData } from "../App.tsx";

export const ProfilePage = () => {
    const passInput = useRef<HTMLInputElement>(null);
    const result = useRef<HTMLDivElement>(null);

    const clickEnter = (e) => {
        if(e.key == "Enter")
            submitChange();
    }

    const submitChange = () => {
        const pass = (passInput.current as HTMLInputElement).value;

        Auth.tryChangePass(pass, result.current);
    }

    return (
    <div className="wrapper">
        <Header/>
        <div className="container-content">
            <h1 className="text-center">Профиль</h1>
            <div className="catalog-group" style={{textAlign: "center"}}>
            <div className="login-container" onKeyDown={clickEnter}>
                        <h2>{publicAuthData.login}</h2>
                            <div className="form-group">
                                <label htmlFor="password">Сменить пароль:</label>
                                <input type="password" name="password" minLength={4} ref={passInput} required/>
                            </div>
                            <div className="result" ref={result}></div>
                            <button type="submit" onClick={submitChange}>Подтвердить</button><br></br>
                            <button type="submit" style={{backgroundColor: "red"}} onClick={Auth.exit}>Выйти</button>
                </div>
            </div>
        </div>
    </div>
    );
}