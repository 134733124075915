import React, { ReactElement, useEffect, useState } from "react";
import Config from "../Config.ts";

export const ChatItem = ({item}) => {
    if(item === undefined) return;

    return (
                    <a className="chat" href={process.env.PUBLIC_URL+ "/chat/" + item['id']}>
                        <div className="chat-avatar">
                            <img src={item['avatar'] == "" ? "https://vk.com/images/icons//multichat80.png" : item['avatar']}/>
                        </div>
                        <div className="chat-info">
                            <div className="chat-info-t">{item['name']}</div>
                            <div className="chat-info-c">{item['count']} участников</div>
                        </div>
                    </a>
    );
}

export const CatalogChat = ({name, url, filter=""}) => {
    const [page, setPage] = useState(1);
    const [block, setBlock] = useState<ReactElement>();

    let changePage = param => e => {
        setPage(param);
    };

    useEffect(() => {
        fetch(`${url}${page}&filter=${filter}`)
            .then(response => response.json())
            .then(data => {
                if(data['records'])
                    {
                        let pages = data['paging']['pages'];
                setBlock(
                <div className="catalog-group">
                <h2>{name}</h2>
                <div className="chat-page">

                        {
                            <div className="chat-row">
                            <div className="chat-column">
                            <ChatItem item={data['records'][0]}/>
                            <ChatItem item={data['records'][1]}/>
                            <ChatItem item={data['records'][2]}/>
                            </div>
                            <div className="chat-column">
                            <ChatItem item={data['records'][3]}/>
                            <ChatItem item={data['records'][4]}/>
                            <ChatItem item={data['records'][5]}/>
                            </div>
                            <div className="chat-column">
                            <ChatItem item={data['records'][6]}/>
                            <ChatItem item={data['records'][7]}/>
                            <ChatItem item={data['records'][8]}/>
                            </div>
                            </div>
                            }
                </div>
                <div className="numb-pages">
                    <span>Страницы: </span><span className="numb-pages-n">{pages.map((value) => { return <a href="javascript:void(0);" onClick={changePage(value['page'])}>{value['page']} </a>  })}</span>
                </div>
                </div>);
                    }
             });
        }, [page]);

    return (
            block
    );
}