import React, { useEffect, useState } from "react";
import { Header } from "./Elements.tsx";
import Config from "../Config.ts";
import { Chart } from 'react-google-charts';
import { publicAuthData } from "../App.tsx";

const Chat = ({data}) => {
    let [arr, setArr] = useState<any[][]>([]);

    useEffect(() => {
        if(data['id']) {
            let arr2: any[][] = [["Дата", "Участники"]];
        data['statistic'].map((value) => {
            arr2.push([value['date'], Number(value['count'])]);
        });
        setArr(arr2);
        }
    }, [data]);

    const deleteChat = id => e => {
        fetch(`${Config.serverBind}/chat/delete.php?id=${id}&token=${localStorage.getItem('token')}`)
            .then(response => response.json())
            .then(data => {
                window.location.href = process.env.PUBLIC_URL + "/";
            });
    };

    if(!data['id'])
        return (<div></div>);

    return (
        <div className="catalog-group">
                <div className="chat-page">
                    <div className="col">
                    <div className="pad-40">
                        <img className="rounded-circle box-160-280" src={data ? data['avatar'] == "" ? "https://vk.com/images/icons//multichat80.png" : data['avatar'] : ""}/>
                    </div>
                    <div className="col-2">
                        <h1>{data ? data['name'] : ""}</h1>
                        <div style={{position: "relative", display: "block"}}>
                            <a className="icona" href={data ? data['url'] : "#"}>Присоединиться</a>
                        </div>
                        {
                            (publicAuthData.isAuth) ?
                        (<div style={{position: "relative", display: "block"}}>
                            <a className="deleteb" href={"#"} onClick={deleteChat(data['id'])}>Удалить</a>
                        </div>) : ("")
                        }
                        <p className="del"></p>
                        <span className="ni">
                            <div className="pad-40 col" style={{flexDirection: "column"}}>
                                <div className="owner-name">Чат был создан:</div>
                                <div>
                                <a href={data ? (data['owner'] ? data['owner'] : "#") : "#"}>
                                <div className="owner-name">{data ? data['owner_name'] : ""}</div>
                                <img className="rounded-circle box-50-80" src={data ? (data['avatar_owner'] == "" ? "https://vk.com/images/icons//multichat80.png" : data['avatar_owner']) : ""}/>
                                </a>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                </div>
                {
                    data['wall'].map((value) => {
                        return (<div className="chat-page">
                        <div>
                            <h1>Приглашение в чат</h1>
                            <div style={{whiteSpace: "pre-wrap"}}>{value['desc']}</div>
                            <br></br>
                            <div>{new Date(Number(value['time'] * 1000)).toLocaleString()}</div>
                            <div><a href={value['url']}>Источник</a></div>
                        </div>
                    </div>)
                    })
                }
                <div className="chat-page">
                    <div className="col">
                        <h1>Статистика участников</h1>
                        <div id="curve_chart" style={{width: "100%", height: "500px"}}>
                            {arr.length > 1 ? <Chart chartType="LineChart" data={arr} /> : ""}
                        </div>
                    </div>
                </div>
            </div>
    );
}

export const ChatPage = ({id}) => {
    const [data, setData] = useState({});

    useEffect(() => {
        fetch(`${Config.serverBind}/chat/get.php?id=${id}`)
            .then(response => response.json())
            .then(data => {
                setData(data);
            });
        }, []);

    return (
    <div className="wrapper">
        <Header/>
        <div className="container-content">
            <h1 className="text-center">{data ? data['name'] : ""}</h1>
            <Chat data={data}/>
        </div>
    </div>
    );
}