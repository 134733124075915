import React from "react";
import { Footer, Header } from "./Elements.tsx";
import { CatalogChat } from "./CatalogChat.tsx";
import Config from "../Config.ts";

export const SearchPage = ({s}) => {
    return (
    <div className="wrapper">
        <Header/>
        <div className="container-content">
            <h1 className="text-center">Результаты поиска</h1>
            <CatalogChat name="Результаты поиска" url={`${Config.serverBind}/chat/search.php?s=${s}&page=`}/>
        </div>
    </div>
    );
}