import { useState } from "react";
import Config from "./Config.ts";

export class AuthData {
    isAuth: boolean = false;
    login: string = "";
}

class Auth {
    public static async getAuth(): Promise<AuthData>
    {
        let authData = new AuthData();
        if(localStorage.getItem('token'))
        {
            let token = localStorage.getItem('token');
            let data = await (await fetch(`${Config.serverBind}/user/auth.php?token=${token}`)).json();
            if(data['login'])
            {
                authData.isAuth = true;
                authData.login = data['login'];
            }
        }

        return authData;
    }

    public static exit()
    {
        localStorage.removeItem('token');
        window.location.reload();
    }

    public static tryAuth(login: string, pass: string, result: HTMLDivElement | null) {
        fetch(`${Config.serverBind}/user/auth.php?login=${login}&pass=${pass}`)
            .then(response => response.json())
            .then(data => {
                if(data['token'])
                {
                    localStorage.setItem('token', data['token']);
                    window.location.reload();
                } else if(result != null) result.innerHTML = data['message'];
            });
    }

    public static tryRegister(login: string, pass: string, result: HTMLDivElement | null) {
        fetch(`${Config.serverBind}/user/register.php?login=${login}&pass=${pass}`)
            .then(response => response.json())
            .then(data => {
                if(data['token'])
                {
                    localStorage.setItem('token', data['token']);
                    window.location.reload();
                } else if(result != null) result.innerHTML = data['message'];
            });
    }

    public static tryChangePass(pass: string, result: HTMLDivElement | null) {
        fetch(`${Config.serverBind}/user/update.php?token=${localStorage.getItem('token')}&password=${pass}`)
            .then(response => response.json())
            .then(data => {
                if(result != null) result.innerHTML = data['message'];
            });
    }
}

export default Auth;