import React, { useEffect, useRef, useState } from "react";
import { Header } from "./Elements.tsx";
import Config from "../Config.ts";
import Auth from "../Auth.ts";

const AuthContainer = () => {
    const loginInput = useRef<HTMLInputElement>(null);
    const passInput = useRef<HTMLInputElement>(null);
    const result = useRef<HTMLDivElement>(null);

    const clickEnter = (e) => {
        if(e.key == "Enter")
            submitAuth();
    }

    const submitAuth = () => {
        const login = (loginInput.current as HTMLInputElement).value;
        const pass = (passInput.current as HTMLInputElement).value;

        Auth.tryAuth(login, pass, result.current);
    }

    return (
        <div className="login-container" onKeyDown={clickEnter}>
                        <h2>Вход в систему</h2>
                            <div className="form-group">
                                <label htmlFor="username">Имя пользователя:</label>
                                <input type="text" id="username" name="username" minLength={4} ref={loginInput} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Пароль:</label>
                                <input type="password" id="password" name="password" minLength={4} ref={passInput} required/>
                            </div>
                            <div className="result" ref={result}></div>
                            <button type="submit" onClick={submitAuth}>Войти</button>
                </div>
    );
};

const RegContainer = () => {
    const loginInput = useRef<HTMLInputElement>(null);
    const passInput = useRef<HTMLInputElement>(null);
    const result = useRef<HTMLDivElement>(null);

    const clickEnter = (e) => {
        if(e.key == "Enter")
            submitReg();
    }

    const submitReg = () => {
        const login = (loginInput.current as HTMLInputElement).value;
        const pass = (passInput.current as HTMLInputElement).value;

        Auth.tryRegister(login, pass, result.current);
    }

    return (
        <div className="login-container" onKeyDown={clickEnter}>
                        <h2>Регистрация</h2>
                            <div className="form-group">
                                <label htmlFor="username">Имя пользователя:</label>
                                <input type="text"name="username" minLength={4} ref={loginInput} required/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Пароль:</label>
                                <input type="password" name="password" minLength={4} ref={passInput} required/>
                            </div>
                            <div className="result" ref={result}></div>
                            <button type="submit" onClick={submitReg}>Зарегистрироваться</button>
                </div>
    );
};

export const AuthPage = () => {
    return (
    <div className="wrapper">
        <Header/>
        <div className="container-content">
            <div className="catalog-group" style={{textAlign: "center"}}>
                <AuthContainer/>
                <RegContainer/>
            </div>
        </div>
    </div>
    );
}