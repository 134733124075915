import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useRoutes } from 'raviger';
import Config from './Config.tsx';
import { MainPage } from './templates/MainPage.tsx';
import { ChatPage } from './templates/ChatPage.tsx';
import { SearchPage } from './templates/SearchPage.tsx';
import { AuthPage } from './templates/AuthPage.tsx';
import { ProfilePage } from './templates/ProfilePage.tsx';
import Auth, { AuthData } from './Auth.ts';

export let publicAuthData: AuthData = new AuthData();

export const App = () => {
    const [authData, setData] = useState(new AuthData());
    const [seed, setSeed] = useState(String(Math.random() * 9000000));

    useEffect(() => { async function getAuth() {
        let tmpData = await Auth.getAuth();
        setData(tmpData);
        publicAuthData = tmpData;
    }

    getAuth();
    }, []);

    let element = useRoutes({
        '/chat/search/:s' : ({s}) => { return authData.isAuth ? <SearchPage s={s}/> : (<AuthPage/>) },
        '/chat/:chatId' : ({chatId}) => { return authData.isAuth ? <ChatPage id={chatId}/> : (<AuthPage/>) },
        '/profile' : () => { return authData.isAuth ? (<ProfilePage/>) : (<AuthPage/>) },
        '*' : () => { return authData.isAuth ? <MainPage seed={seed} /> : (<AuthPage/>) }
    });

    return element;
};