import React, { useEffect } from "react";
import { publicAuthData } from "../App.tsx";
import Auth from "../Auth.ts";

export const Footer = () => {
    return (
        <footer>
            <p> </p>
        </footer>
    );
}

export const Header = () => {
    let search = (e) => {
        if(e.key == 'Enter')
            window.location.href = process.env.PUBLIC_URL + `/chat/search/${e.target.value}`;
    };

    useEffect(() => {

    }, [publicAuthData]);

    return (
        <div className="header">
            <div className="navbar">
                <div className="container-content bar">
                        <div className="col">
                            <div className="left-c">
                            <a href={process.env.PUBLIC_URL + "/"} className="navbar-logo"><img src={process.env.PUBLIC_URL + "/img/logo.png"}/></a>
                            <div className="chats-search">
                                <div className="relative">
                                    <input id="search" placeholder="Введите название..." type="text" autoComplete="off" onKeyDown={search}/>
                                    <img src={process.env.PUBLIC_URL + "/img/search.png"} className="search-icon"/>
                                </div>
                            </div>
                            </div>
                            <div className="right-c">
                                <a href={process.env.PUBLIC_URL + "/profile"} className="btn-new login-btn">
                                    <span>{publicAuthData.isAuth ? publicAuthData.login : "Войти"}</span>
                                </a>
                            </div>
                        </div>
                        <div className="justify">
                        
                        </div>
                    </div>
            </div>
        </div>
    );
}