import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App.tsx';
import { Footer } from './templates/Elements.tsx';

const body = document.getElementById('root');
if(body) {
const root = ReactDOM.createRoot(body);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
      <Footer/>
    </BrowserRouter>
  </React.StrictMode>
);
}