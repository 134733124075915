import React, { useEffect, useState } from "react";
import { Footer, Header } from "./Elements.tsx";
import { CatalogChat } from "./CatalogChat.tsx";
import Config from "../Config.ts";

export const MainPage = ({seed}) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        fetch(`${Config.serverBind}/chat/count.php`)
            .then(response => response.json())
            .then(data => {
                if(data['count'])
                {
                    setCount(data['count']);
                }
            });
    }, [])

    return (
    <div className="wrapper">
        <Header/>
        <div className="container-content">
            <h1 className="text-center">Каталог ВК бесед</h1>
            <h2 className="text-center">Всего бесед на сайте: {count}</h2>
            <CatalogChat name="Новые беседы" filter="new" url={`${Config.serverBind}/chat/read_paging.php?page=`}/>
        </div>
    </div>
    );
}